import { nada } from "expose";
// @ts-expect-error: Not yet typed
import { General } from "libcs/General";
// @ts-expect-error: Not yet typed
import { niceprint } from "libcs/Essentials";
// @ts-expect-error: Not yet typed
import { namespace } from "libcs/Namespace";
// @ts-expect-error: Not yet typed
import { evaluate } from "libcs/Evaluator";
const Json = {
    _helper: {
        GenJSONAtom(key, val) {
            return {
                ctype: "JSON",
                value: {
                    key: General.string(key),
                    value: val,
                },
            };
        },
        forall(li, runVar, fct, modifs = {}) {
            var _a;
            const iteratorType = ((_a = modifs.iterator) === null || _a === void 0 ? void 0 : _a.value) || "value";
            let res;
            for (const k in li) {
                if (iteratorType === "key") {
                    namespace.setvar(runVar, General.string(k));
                }
                else if (iteratorType === "pair") {
                    namespace.setvar(runVar, this.GenJSONAtom(k, li[k]));
                }
                else {
                    namespace.setvar(runVar, li[k]);
                }
                res = evaluate(fct);
            }
            return res;
        },
        niceprint(a, modifs, options) {
            if (a.ctype === "JSON") {
                return Json.niceprint(a, modifs, options);
            }
            return niceprint(a);
        },
        handlePrintException(e) {
            if (e instanceof RangeError) {
                console.log("Warning: Dictionary string could not be generated! Probably large cyclic Dictionary!");
            }
            else if (e instanceof SyntaxError) {
                console.log("Warning: Dictionary string could not be parsed!");
            }
            else {
                console.log("Warning: Dictionary printing failed!");
            }
        },
    },
    turnIntoCSJson(a) {
        return {
            ctype: "JSON",
            value: a,
        };
    },
    getField(obj, key) {
        var _a;
        return ((_a = obj.value) === null || _a === void 0 ? void 0 : _a[key]) || nada;
    },
    setField(where, field, what) {
        if (what.ctype === "undefined" && where[field]) {
            delete where[field];
        }
        else {
            where[field] = what;
        }
    },
    GenFromUserDataEl(el) {
        const key = el.key;
        const obj = el.value;
        if ((key === null || key === void 0 ? void 0 : key.ctype) !== "string") {
            console.log("Error: JSON keys have to be strings.");
            return nada;
        }
        if (obj == undefined) {
            console.log("Warning: JSON object not defined.");
            return {
                key: key.value,
                val: nada,
            };
        }
        return {
            key: key.value,
            val: evaluate(obj),
        };
    },
    niceprint(el, modifs, options) {
        const niceprintOptions = options || {
            printedWarning: false,
            visitedMap: {
                tracker: new WeakMap(),
                level: 0,
                maxLevel: 1000,
                maxElVisit: 5000,
                newLevel: false,
                printedWarning: false,
            },
        };
        if (modifs === null || modifs === void 0 ? void 0 : modifs.maxDepth) {
            const depth = evaluate(modifs.maxDepth);
            if (depth.ctype === "number")
                niceprintOptions.visitedMap.maxLevel = depth.value.real;
        }
        const visitedMap = niceprintOptions.visitedMap;
        visitedMap.newLevel = true;
        visitedMap.level += 1;
        const keys = Object.keys(el.value).sort();
        const jsonString = "{" +
            keys
                .map(function (key) {
                const elValKey = el.value[key];
                if (!visitedMap.tracker.has(elValKey)) {
                    visitedMap.tracker.set(elValKey, 1);
                }
                else {
                    if (visitedMap[elValKey] > visitedMap.maxElVisit || visitedMap.level > visitedMap.maxLevel) {
                        if (niceprintOptions && !niceprintOptions.printedWarning) {
                            console.log("Warning: We visited a key-value pair very often or encountered a very deeply nested dictionary. Dictionary is probably cyclic. Output will be probably incomplete.");
                            niceprintOptions.printedWarning = true;
                        }
                        return key + ":" + "...";
                    }
                    if (visitedMap.newLevel) {
                        visitedMap.tracker.set(elValKey, visitedMap.tracker.get(elValKey) + 1);
                        visitedMap.newLevel = false;
                    }
                }
                return key + ":" + Json._helper.niceprint(elValKey, modifs, niceprintOptions);
            })
                .join(", ") +
            "}";
        return jsonString;
    },
};
